import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ContactsDTO from "../features/Contacts/ContactsDTO";

import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();

const ContactsContainer = styled.div`
  padding: 5rem 0;
`

const Contacts = ({ data }) => {
  const contacts = new ContactsDTO(data.contacts);
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'contacts',
      title: 'Контакты',
    },
  ]);
  return (
    <Layout crumbs={ crumbs }>
      <SEO title="landcruiserparts.ru" />
      <ContactsContainer className="container">
      <h3>Контактная информация</h3>
      <div>телефоны: {contacts.phoneMain}, {contacts.phoneSecondary}</div>
      <div>email: {contacts.email}</div>
      <h3>Наш адрес</h3>
      <div>{contacts.address}</div>
      <h3>Как нас найти</h3>
      <div>{contacts.addressDetails}</div>
      <h3>Мы на карте</h3>
      <iframe
        title="map"
        src={contacts.yandexMapLink}
        width="100%"
        height="480"
        frameBorder="0"
      ></iframe>
      </ContactsContainer>
    </Layout>
  )
}

export const query = graphql`
  query CONTACTS_PAGE_QUERY {
    contacts:configYaml(type: {eq: "contacts"}) {
      adderss_details
      address
      email
      phone
      phone_1
      yandex_map_link
    }
  }
`; 

export default Contacts
